<template>
  <section class="media-wrapper">
    <section class="filter">
      <el-form :inline="true">
        <el-form-item label="类型">
          <el-select v-model="mediumType" size="small" placeholder="请选择媒体类型">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in siteTypeList" :label="item.siteName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="域名/名称">
          <el-input v-model="domain" size="small" placeholder="请输入域名或名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="search" :disabled="loading">查询</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="media-list" v-loading="loading">
      <div class="btn-wrap">
        <el-button size="small" type="primary" @click="dialogVisible = true">创建</el-button>
        <el-button size="small" type="primary" @click="openFixDataDialog">数据修复</el-button>

        <el-button size="small" type="primary" v-if="batchEditStatus === false" @click="startBatchEdit" style="float: right">编辑</el-button>
        <el-button size="small" type="info" v-if="batchEditStatus === true" @click="cancelBatchEdit" style="float: right">取消</el-button>
        <el-button size="small" type="primary" v-if="batchEditStatus === true" @click="submitBatchEdit" style="float: right">保存</el-button>
      </div>
      <el-table :data="tableData" border ref="table" style="width: 100%">

        <el-table-column  :resizable="false" prop="sourceName" align="center" label="网站名称" sortable>
          <template scope="{ row }">
            <el-input v-model="row.sourceName" size="small" v-show="!batchEditStatus && row.show"></el-input>
            <span v-show="!batchEditStatus && !row.show">{{row.sourceName}}</span>
            <el-input v-model="row.sourceName" size="small" v-show="batchEditStatus"></el-input>
          </template>
        </el-table-column>
        <el-table-column  :resizable="false" prop="domain" align="center" label="网站域名"/>

        <el-table-column  :resizable="false" prop="sourceType" align="center" label="类型" >
          <template scope="{ row }">
            <span v-show="!batchEditStatus && !row.show">{{ row.sourceType }}</span>
            <el-select v-show="!batchEditStatus && row.show" size="small" v-model="row.sourceType" placeholder="请选择媒体类型">
              <el-option v-for="item in siteTypeList" :label="item.siteName" :value="item.id"></el-option>
            </el-select>
            <el-select v-show="batchEditStatus" size="small" v-model="row.sourceType" placeholder="请选择媒体类型">
              <el-option v-for="item in siteTypeList" :label="item.siteName" :value="item.id"></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column v-if="!batchEditStatus"  :resizable="false" label="操作" align="center">
          <template scope="{ row }">
            <div v-show="!row.show">
              <el-button type="text" @click="startEdit(row)">编辑</el-button>
              <el-button type="text" style="color: #f56c6c;" @click="delectMedia(row.id)">删除</el-button>
            </div>
            <div v-show="row.show">
              <el-button type="text" @click="updateMediaType (row)">保存</el-button>
              <el-button type="text" style="color:#909399" @click="endEdit(row)">取消</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <section class="list-bottom">
        <span class="count">共{{count}}条记录</span>
        <el-pagination :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
                       @size-change="sizeChange" @current-change="currentChange" :total="count">
        </el-pagination>
      </section>
      <el-dialog title="创建" :visible.sync="dialogVisible" width="50%">
        <el-form label-position="top" label-width="100px" :model="form" ref="addForm" :rules="rules">
          <el-form-item label="媒体类型" prop="mediumType">
            <el-select v-model="form.mediumType" placeholder="请选择媒体类型">
              <el-option v-for="item in siteTypeList" :label="item.siteName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="网站域名" prop="domain">
            <el-input v-model="form.domain"></el-input>
          </el-form-item>
          <el-form-item label="网站名称" prop="sourceName">
            <el-input v-model="form.sourceName"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelMarkGroup">取 消</el-button>
          <el-button type="primary" @click="saveMedia">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="删除站点"
        :visible.sync="deleteDialogFlag"
        width="20%"
        @closed="handleClose">
        <el-form v-loading="delLoading" ref="form" :model="delform" label-width="80px">
          <el-form-item label="删除方式">
            <el-radio-group v-model="delform.way">
              <el-radio label="直接删除"></el-radio>
              <el-radio label="转移数据并删除"></el-radio>
            </el-radio-group>
          </el-form-item>
          <span v-if="delform.way === '直接删除'" style="color: red;font-size: 12px;margin-left: 10px">* 直接删除：该域名下的相关数据也会被同时删除</span>
          <el-form-item label="数据转移" v-if="delform.way === '转移数据并删除'">
            <el-input v-model="delform.domain" placeholder="数据转移至某站点的域名"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteDialogFlag = false">取 消</el-button>
          <el-button type="primary" @click="confirmDelMedia()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="数据站点修复" :visible.sync="fixDataDialog" v-loading="fixDataLoading" width="35%">
        <el-form ref="form" :model="fixData" label-width="100px" style="width: 100%">
          <el-form-item label="URL匹配方式">
            <div>
              <el-radio-group v-model="fixData.matchType">
                <el-radio label="精确匹配"></el-radio>
                <el-radio label="通配符匹配"></el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="匹配规则">
            <el-input style="width: 20%" v-model="fixData.http" :readonly="true" disabled></el-input>
            <el-input style="width: 80%" v-model="fixData.domain" v-if="fixData.matchType === '精确匹配'" placeholder="请输入完整精确的网站域名，如store.mini.com"></el-input>
            <el-input style="width: 80%" v-model="fixData.domain" v-if="fixData.matchType === '通配符匹配'" placeholder="请按照通配符形式输入网站域名，如*.mini.com 或 *.mini.*"></el-input>
          </el-form-item>
          <el-form-item label="时间范围">
            <el-date-picker
              style="width: 100%"
              v-model="fixData.dateRange"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="替换为">
            <el-select v-model="fixData.medium" filterable remote :remote-method="remoteMethod" placeholder="请输入站点名称进行搜索并选择" style="width: 100%">
              <el-option-group
                v-for="group in options"
                :key="group.label"
                :label="group.label">
                <el-option
                  v-for="item in group.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-form>
        <div style="width: 100%;text-align: center" v-if="replaceCheck">
          <el-tag size="small">检测到系统中存在{{ replaceCount }}条数据需要进行替换</el-tag>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="requestFixDataCheck">数据查找</el-button>
          <el-button type="primary" @click="goFixData">替换</el-button>
        </span>
      </el-dialog>
    </section>
  </section>
</template>

<script>
import { addMedia, delectMedia, getAllMedia, updateMedia, updateMediaType, getAllMediaGroupBySId, fixDataCheck, fixData, batchEditUpdate } from '@/api/mediaSource'
import { listAllSiteType } from '@/api/detection'
export default {
  mounted () {
    this.listSiteType()
    this.getList()
  },
  data () {
    return ({
      delLoading: false,
      fixDataLoading: false,
      rowdata: '',
      domain: '',
      mediumType: '',
      count: 0,
      editType: false,
      currentPage: 1,
      size: 10,
      loading: false,
      tableData: [],
      siteTypeList: [],
      dialogVisible: false,
      deleteDialogFlag: false,
      form:{
        mediumType:'',
        domain:'',
        sourceType:'',
        sourceName:''
      },
      rules: {
        mediumType: [
          { required: true, message: '请选择媒体类型', trigger: 'change' }
        ],
        domain: [
          { required: true, message: '请输入网站地址', trigger: 'blur' },
        ],
        sourceName: [
          { required: true, message: '请输入网站名称', trigger: 'blur' },
        ]
      },
      cancelList: [],
      delform: {
        id: 0,
        domain: '',
        way: '直接删除'
      },
      fixDataDialog: false,
      fixData: {
        matchType: '精确匹配',
        domain: '',
        dateRange: '',
        medium: '',
        http: 'http(s)://'
      },
      options: [{
        label: '请输入站点名称进行搜索'
      }],
      replaceCount: 0,
      replaceCheck: false,
      batchEditStatus: false,
      oldTableData: []
    })
  },
  methods: {
    startBatchEdit () {
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].show) {
          this.endEdit(this.tableData[i])
        }
      }
      this.batchEditStatus = true
      this.oldTableData = JSON.parse(JSON.stringify(this.tableData))
    },
    cancelBatchEdit () {
      this.batchEditStatus = false
      this.tableData = JSON.parse(JSON.stringify(this.oldTableData))
    },
    async submitBatchEdit () {
      this.loading = true
      const res = await batchEditUpdate(this.tableData)
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.batchEditStatus = false
        this.getList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async startEdit (row) {
      console.log(row)
      this.rowdata = row.sourceType
      this.rowsid = row.mediumType
      this.cancelList[row.id] = row.sourceName
      row.show = true
    },
    async endEdit (row) {
      row.sourceType = this.rowdata
      row.mediumType = this.rowsid
      row.sourceName = this.cancelList[row.id]
      row.show = false
    },
    async updateMedia (row) {
      row.show = false
      const res = await updateMedia({id:row.id, sourceName:row.sourceName,mediumType: row.mediumType})
      if (res.code === 0) {
        this.getList()
        this.$message.success('修改成功')
      } else {
        this.$message.error('修改失败')
      }
    },
    async updateMediaType (row) {
      this.loading = true
      if (isNaN(row.sourceType)) {
        row.sourceType = row.mediumType
      }
      const res = await updateMediaType(row)
      if (res.code === 0) {
        this.getList()
        row.show = false
        this.$message.success('修改成功')
      } else {
        this.$message.error('修改失败')
      }
    },
    delectMedia (id) {
      this.delform.id = id
      this.deleteDialogFlag = true
    },
    handleClose () {
      this.delform.id = 0
      this.delform.domain = ''
      this.delform.way = '直接删除'
    },
    async confirmDelMedia () {
      this.delLoading = true
      const res = await delectMedia({ id: this.delform.id, domain: this.delform.domain })
      this.delLoading = false
      if (res.code === 0) {
        this.$message.success('删除成功')
        this.getList()
      } else {
        this.$message.error('删除失败')
      }
    },
    async listSiteType () {
      const res = await listAllSiteType()
      this.siteTypeList = res.data
    },
    async search () {
      this.currentPage = 1
      this.getList()
    },
    async getList () {
      this.loading = true
      const res = await getAllMedia({ current: this.currentPage, pageSize: this.size, keyword: this.domain, mediaId:this.mediumType })
      res.data.dataList.forEach(element => {
        element["show"] = false
      })
      this.tableData = res.data.dataList
      this.count = res.data.count
      this.loading = false
    },
    // 取消添加
    cancelMarkGroup () {
      this.dialogVisible = false
      this.$refs.addForm.resetFields()
    },
    // 保存
    saveMedia () {
      this.siteTypeList.forEach((item,index) => {
        if(this.form.mediumType === item.id){
          this.form.sourceType = item.siteName
        }
      })
      this.$refs['addForm'].validate(async (valid) => {
        if (valid) {
          const res = await addMedia(this.form)
          if (res.code === 0) {
            this.currentPage = 1
            this.dialogVisible = false
            this.$refs.addForm.resetFields()
            this.getList()
            this.$message.success('添加成功')
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      });
    },
    sizeChange: async function (i) {
      this.loading = true
      this.currentPage = 1
      this.size = i
      this.getList()
    },
    currentChange: async function (i) {
      this.loading = true
      this.currentPage = i
      this.getList()
    },
    async openFixDataDialog () {
      this.replaceCheck = false
      this.replaceCount = 0
      this.fixData.matchType = '精确匹配'
      this.fixData.domain = ''
      this.fixData.dateRange = ''
      this.fixData.medium = ''
      this.fixDataDialog = true
    },
    async remoteMethod (query) {
      const res = await getAllMediaGroupBySId(query)
      this.options = res.data
    },
    async requestFixDataCheck () {
      if (!this.fixData.domain || !this.fixData.dateRange) {
        this.$message.error('匹配规则或时间范围不可为空')
        return
      }
      this.fixDataLoading = true
      const res = await fixDataCheck(this.fixData)
      if (res.code === 0) {
        this.fixDataLoading = false
        this.replaceCount = res.data
        this.replaceCheck = true
      }
    },
    async goFixData () {
      if (!this.fixData.domain || !this.fixData.dateRange) {
        this.$message.error('匹配规则或时间范围不可为空')
        return
      }
      this.fixDataLoading = true
      const res = await fixData(this.fixData)
      if (res.code === 0) {
        this.fixDataLoading = false
        this.$message.success('替换完成')
        this.fixDataDialog = false
        this.search()
      } else {
        this.fixDataLoading = false
        this.$message.error('替换失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped="">
.media-wrapper{
  width: 100%;
  height: 100%;
}
.filter {
  width: 100%;
  height: 70px;
  background: #fff;
  border-radius: 2px;
  padding-top: 15px;
  padding-left: 20px;
  box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
}
.media-list {
  width: 100%;
  margin-top: 20px;
  min-height: 100%;
  background: #fff;
  border-radius: 2px;
  padding: 20px 20px;
  box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  .btn-wrap {
    margin-bottom: 25px;
  }

  .list-bottom {
    display: flex;
    justify-content: space-between;
    /* position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px; */
    .count {
      line-height: 32px;
    }
  }
  .selected {
    background: rgba(64,158,255, .2);
  }
  .el-dialog {
    .prompt {
      height: 28px;
      display: flex;
      align-items: center;
      .el-icon-warning {
        font-size: 28px;
        color: #FF8B35;
        margin-right: 12px;
      }
    }
  }
}
</style>
