import request from '@/utils/request'

// 创建媒体来源
export function addMedia (data) {
  return request({
    url: '/mediaSource/addMediaSoure',
    method: 'POST',
    data
  })
}
// 删除
export function delectMedia (data) {
  return request({
    url: '/mediaSource/delectMediaSoureById',
    method: 'POST',
    data
  })
}
// 获取来源列表
export function getAllMedia (data) {
  return request({
    url: '/mediaSource/getAllMediaSoure',
    method: 'POST',
    data
  })
}
// 修改来源名称
export function updateMedia (data) {
  return request({
    url: '/mediaSource/updateMediaSoureById',
    method: 'POST',
    data
  })
}
export function updateMediaType (data) {
  return request({
    url: '/mediaSource/updateMediaSoureType',
    method: 'POST',
    data
  })
}
export function getAllMediaGroupBySId (data) {
  return request({
    url: '/mediaSource/fetchAllSiteGroupBySId',
    method: 'POST',
    data
  })
}
export function fixDataCheck (data) {
  return request({
    url: '/mediaSource/fixDataCheck',
    method: 'POST',
    data
  })
}
export function fixData (data) {
  return request({
    url: '/mediaSource/fixData',
    method: 'POST',
    data
  })
}
export function batchEditUpdate (data) {
  return request({
    url: '/mediaSource/batchEditUpdate',
    method: 'POST',
    data
  })
}
